import React, { Component } from "react";
import Slider from "react-slick";
import "./HeroSlider.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import { animationContainer, fadeIn } from "../../../animations/variants";
import Slide from "./Slide";

export default class HeroSlider extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      autoplay: true,
      autoplaySpeed: 8000,
    };

    const slides = [
      // {
      //   title: {
      //     line1: "Experience The ",
      //     line2: "Finest Coffee And Food",
      //     line3: " At Gloria Jean’s Coffees",
      //   },
      //   subTitle:
      //     "From cozy hot drinks to a delicious food menu, Gloria Jean's Coffees is open to serve you the best.",
      //   imgUrl: "Assets/img/home/hero-slider/hero1-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Enjoy Our Chicken ",
      //     line2: "Prego Burger",
      //   },
      //   subTitle:
      //     "Spice up your day with the kick of our zesty Prego Burger. Grilled to perfection with a spicy kick.",
      //   imgUrl: "Assets/img/home/hero-slider/prego-burger-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Try Our Icy & Zesty ",
      //     line2: "Gingerbread ",
      //     line3: "Chiller",
      //   },
      //   subTitle:
      //     "Ready for the festive season? Try our icy and zesty Gingerbread Chiller in stores today!",
      //   imgUrl: "Assets/img/home/hero-slider/gingerbread-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Steamed Prawn ",
      //     line2: "Momo",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/prawn-momo-min.jpg",
      // },
      {
        title: {
          line1: "Visit Us In Stores And Try Our ",
          line2: "Glorious Coffee Blends",
          line3: "",
        },
        subTitle:
          "Finest arabica beans roasted with care to add an extra bit of energy to your busy life.",
        imgUrl: "Assets/img/home/hero-slider/coffee1-min.jpg",
      },
      // {
      //   title: {
      //     line1: "Meat Lover's ",
      //     line2: "Platter",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/meet-lovers-platter-min.jpg",
      // },

      {
        title: {
          line1: "Savor A Meaty Treat With ",
          line2: "Kebab Platter",
        },
        subTitle:
          "Not just a meal, but an experience of flame-kissed juicy meats.",
        imgUrl: "Assets/img/home/hero-slider/kebab-platter-min.jpg",
      },

      {
        title: {
          line1: "Checkout Our Delicious ",
          line2: "Chillers",
        },
        subTitle:
          "Chilling with chillers gets better and more refreshing when it's from your favorite Gloria Jean's Coffees.",
        imgUrl: "Assets/img/home/hero-slider/chiller1-min.jpg",
      },

      {
        title: {
          line1: "Indulge Into ",
          line2: "Blissful Desserts",
        },
        subTitle:
          "Satisfy your sweet tooth with our handcrafted desserts prepared with creativity and passion.",
        imgUrl: "Assets/img/home/hero-slider/cheesy-caramel-cheesecake-min.jpg",
      },
      // {
      //   title: {
      //     line1: "Mediterranean Chicken ",
      //     line2: "Salad",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/crispy-chicken-salad-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Spaghetti ",
      //     line2: "Marinara",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/spaghetti-marinara-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Vibe With The ",
      //     line2: "World-Class ",
      //     line3: "Coffee Brand",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/coffee3-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Roasted Beef ",
      //     line2: "Sandwich",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/roasted-beef-sandwich-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Sip Into Our ",
      //     line2: "Summer ",
      //     line3: "Chillers",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/chillers2-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Kebab ",
      //     line2: "Platter",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/kebab-platter-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Thai Lemon ",
      //     line2: "Chicken ",
      //     line3: "Lolly",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/thai-lemon-chicken-lolly-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Latino Chicken ",
      //     line2: "Burger",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/latino-chicken-burger-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Blue Berry ",
      //     line2: "Blast",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/blue-berry-blast-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Have A ",
      //     line2: "Glorious ",
      //     line3: "Day",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/coffee4-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Enjoy The Best Coffee ",
      //     line2: "Experience",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/coffee2-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Beef Pepperoni ",
      //     line2: "Pizza",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/beef-pepperoni-pizza-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Chicken Fajita ",
      //     line2: "Fettuccini",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/chicken-fajita-fettuccine-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Big Breakfast With ",
      //     line2: "Coffee",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/big-breakfast-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Smooth ",
      //     line2: "Praline",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/smooth-praline-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Steamed Chicken ",
      //     line2: "Momo",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/chicken-momo-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Oceania",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/oceania-min.jpg",
      // },

      // {
      //   title: {
      //     line1: "Chicken ",
      //     line2: "Quesadilla",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/chicken-quesadilla-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Cheesy Marsh ",
      //     line2: "Hawk",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/cheesy-marsh-hawk-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Beef ",
      //     line2: "Lasagna",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/beef-lasagna-min.jpg",
      // },
      // {
      //   title: {
      //     line1: "Fish Schnitzel ",
      //     line2: "Sandwich",
      //   },
      //   imgUrl: "Assets/img/home/hero-slider/fish-stitzel-sandwich-min.jpg",
      // },
    ];

    const totalSlides = slides.length;

    return (
      <motion.div
        animate="visible"
        initial="hidden"
        variants={animationContainer}
        className="sliderContainer pattern-bg"
      >
        <Slider {...settings}>
          {slides.map((slide, i) => (
            <motion.div
              key={"slide" + i}
              variants={fadeIn}
              className="slide-item"
            >
              <Slide
                title={slide.title}
                subTitle={slide.subTitle}
                imgUrl={slide.imgUrl}
                index={i}
                totalSlides={totalSlides}
              />
            </motion.div>
          ))}
        </Slider>
      </motion.div>
    );
  }
}
